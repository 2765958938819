// Navigation Style

header{
    background: $telewosh-blue;
    text-align: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    .logo{
        display: flex;
        height: 65px;
        justify-content: center;
        align-items: center;
        img{
            max-height: 50px;
        }
    }
    nav{
        position: absolute;
        text-align: left;
        top: 100%;
        width: 100%;
        left: 0;
        background: $telewosh-blue;
        transform: scale(1, 0);
        transform-origin: top;
        transition: transform 400ms ease-in-out;
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin-bottom: 1em;
                margin-left: 1em;
            }
        }
        a{
            color: $telewosh-white;
            text-decoration: none;
            font-size: 1.2rem;
            text-transform: uppercase;
            opacity: 0;
            transition: opacity 150ms ease-in-out;
            &:hover{
                color: $telewosh-white;
            }
        }
    }
    .nav-toggle{
        display: none;
    }
    .nav-toggle-label{
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 1em;
        height: 100%;
        display: flex;
        align-items: center;
        span, span::before, span::after{
            display: block;
            background: $telewosh-white;
            height: 2px;
            width: 2em;
            border-radius: 2px;
            position: relative;
        }
        span::before, span::after{
            content: '';
            position: absolute;
        }
        span::before{
            bottom: 7px;
        }
        span::after{
            top: 7px;
        }
    }
    .nav-toggle{
        &:checked ~ nav {
            transform: scale(1, 1);
        }
        &:checked ~ nav a{
            opacity: 1;
            transition: opacity 250ms ease-in-out 250ms;
        }
    }
}