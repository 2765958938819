// Place all the styles related to the assessments controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.btn-new-assessment{
    margin: 10px 40px;
    font-size: 1.5em;
}

.btn-assessment-manage{
    margin: 5px 0;
}

#main-sections-menu{
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .btn-report{
        grid-column: 1/3;
    }
    .h-section-icon{
        height: 50px;
        width: 50px;
    }
}

.report-status{
    padding: 20px;
}