.social-login{
    .social-login{
        width: 200px;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .social-btn{
        font-size: 1.3rem;
        font-weight: 100;
        color:white;
        width: 100%;
        height: 56px;
        &:nth-child(1){
            margin-bottom: 5px;
        }
        span{
            display: none;
        }
    }
    .facebook-btn{
        background-color:#3C589C;
        &:after{
            content:'Facebook';
        }
    }
    .google_oauth2-btn{
        background-color: #DF4B3B;
        &:after{
            content:'Google';
        }
    }
}

.user-sign-fields{
    .form-control:focus {
        z-index: 2;
    }
    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    input[type="password"] {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}