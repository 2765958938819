@media screen and (min-width: 800px){
    header{
        display: grid;
        grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
        .nav-toggle-label{
            display: none;
        }
        .logo{
            grid-column: 2 / span 1;
            img{
                height: 100px;
            }
        }
        nav{
            grid-column: 3 / 4;
            // all: unset; NO EDGE SUPPORT (OLD)
            position: relative;
            text-align: left;
            transition: none;
            transform: scale(1,1);
            background: none;
            top: initial;
            left: initial;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            ul{
                display: flex;
                li{
                    margin-left: 3em;
                    margin-bottom: 0;
                    a{
                        opacity: 1;
                        position: relative;
                        &:hover{
                            text-decoration: none;
                        }
                        &::before{
                            content: '';
                            display: block;
                            height: 5px;
                            background: $telewosh-white;
                            position: absolute;
                            bottom: -0.75em;
                            left: 0;
                            right: 0;
                            transform: scale(0, 1);
                            transform-origin: left;
                            transition: transform ease-in-out 250ms;
                        }
                        &:hover::before{
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }
    }
}