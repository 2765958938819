// Place all the styles related to the categories controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.btn-menu{
    margin-top: 10px;
}

.btn-hazards{
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-areas: "h-title h-details";
    .btn-h-title{
        grid-area: h-title;
        border-right: 2px solid white;
        padding-right: 10px;
    }
    .btn-h-details{
        grid-area: h-details;
        justify-self: center;
        align-self: center;
    }
}