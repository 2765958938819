*, *::before, *::after{
    box-sizing: border-box;
}

body{
    margin: 0;
    background-color: $bg-grey-colour;
    // font-family: 'Open Sans', sans-serif;
    // font-weight: 400;
}

p{
    text-align: justify;
}

.btn-back{
    width: 80px;
    margin-top: 20px;
}

// .logo{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     img{
//         max-width: 320px;
//     }
// }

// ul{
//     width: 100%;
//     padding: 0;
    
// }

#content{
    display: grid;
    padding: 75px 15px 60px 15px;
}

// header{
//     background-color: #000;
//     border-bottom: 2px solid #fff;
//     width: 100%;
//     position: fixed;
//     left: 0;
//     top: 0;
//     padding: 10px;
// }

footer{
    background-color: $telewosh-footer;
    border-top: 2px solid #fff;
    color: $telewosh-white;
    width: 100%;
    height: 60px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
    justify-content: space-between;
    display: flex;
}

.cerides-logo{
    width: 250px;
}