// Place all the styles related to the measures controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.media-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.btn-measures{
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-areas: "m-title m-details";
    .btn-m-title{
        grid-area: m-title;
        border-right: 2px solid white;
        padding-right: 10px;
    }
    .btn-m-details{
        grid-area: m-details;
        justify-self: center;
        align-self: center;
    }
}