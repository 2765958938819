// Place all the styles related to the home controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.welcome_c{
    display: grid;
    ol{
        grid-column: 1/2;
    }
    p{
        grid-column: 2/2;
        align-self: flex-end;
        justify-self: center;
    }
}

.home-logos{
    width: 100%;
    height: auto;
    margin: 20px 0;
}