// Place all the styles related to the h_sections controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.btn-categories{
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-areas: "c-title c-details";
    .btn-c-title{
        grid-area: c-title;
        border-right: 2px solid white;
        padding-right: 10px;
    }
    .btn-c-details{
        grid-area: c-details;
        justify-self: center;
        align-self: center;
    }
}